import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: 0,
  help: false,
};

export const formSlice = createSlice({
  name: "form",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    nextForm: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value += 1;
    },
    prevForm: (state) => {
      state.value -= 1;
    },
    // Use the PayloadAction type to declare the contents of `action.payload`
    formSelect: (state, action) => {
      state.value = action.payload;
    },
    toggleHelp: (state) => {
      state.help = state.help ? false : true;
    },
  },
});

export const { nextForm, prevForm, formSelect, toggleHelp } = formSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectForm = (state) => state.form.value;
export const selectHelp = (state) => state.form.help;

export default formSlice.reducer;
