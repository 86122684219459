import React from "react";
import FormOne from "./screens/formOne";
import Container from "./components/container.js";
import styledComponents from "styled-components";
import { useSelector } from "react-redux";
import { selectForm, selectHelp } from "./features/form/formSlice";
import FormTwo from "./screens/formTwo";
import FormThree from "./screens/formThree";
import FormFour from "./screens/formFour";
import Guide from "./screens/guide";
import PrintablePDF from "./screens/PrintablePDF";
import bg from "./image/bg.svg";
import TL from "./image/TransactionsLogo.png";
const Styles = styledComponents.div`
background-color:#5b3454;

.bg{
  object-fit: cover;
  z-index:0;
  position:absolute;
bottom:0;
}
*{
  z-index:3;
}
.logo{
  width:110px;
  position:absolute;
  left:25px;
  top:25px;
}
width:100vw;
height:100vh;
>h1{
  text-align:center;
  padding:150px 10px;
  display:none;
  z-index:3;
  color:white;
}
@media (max-width:720px) {
  div{
    display:none;
  }
  h1{
    display:block;
  }
  .logo{
    left:50%;
    transform:translate(-50%,0);
  }
}
`;

const switchForm = (form) => {
  switch (form) {
    case 0:
      return <FormOne />;
    case 1:
      return <FormTwo />;
    case 2:
      return <FormThree />;
    case 3:
      return <FormFour />;
    case 4:
      return <PrintablePDF />;
    default:
      return <h1>Error, no form</h1>;
  }
};

//TODO: you need to make the guide here

function App() {
  const form = useSelector(selectForm);
  const help = useSelector(selectHelp);

  return (
    <Styles className="App">
      <img src={bg} alt="background" className="bg" />
      <img src={TL} alt="Tmost" className="logo" />
      <Container>
        {help && <Guide />}
        {switchForm(form)}
      </Container>
      <h1>لضمان جودة التطبيق،يرجى الدخول من شاشة أكبر.</h1>
    </Styles>
  );
}

export default App;
