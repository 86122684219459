import React, { useState } from "react";
import styledComponents from "styled-components";
import { MainButton } from "../../components/window";
import img from "./tmost.png";
import bookURL from "./book.pdf";
import appsSoluURL from "./appsSolu.pdf";
import appsURL from "./apps.pdf";
import manualURL from "./manual.pdf";
import historyURL from "./history.pdf";

const Styles = styledComponents.div`
position:relative;
height:100%;
display:flex;
flex-direction:column;
gap:10px;
img{
  position:absolute;
  width:60px;
  height:60px;
  top:30px;
  left:0;
  z-index:3;
}
iframe{
  position:absolute;
  top:30px;
  left:0;
  z-index:1;

}
button{
width:160px;
}
.videoControl{
  width:30px;
  height:30px;
}
.controls{
  position:absolute;
  display:flex;
  bottom:25px;
  left:0px;
  
}
`;

const videos = [
  "https://www.youtube.com/embed/D7WxGRQtI4E",
  "https://www.youtube.com/embed/JTEqwnnX7fA",
  "https://www.youtube.com/embed/Skux5sw019s",
  "https://www.youtube.com/embed/ldvPfntdkII",
  "https://www.youtube.com/embed/4VYQwhSdFKA",
  "https://www.youtube.com/embed/3dr2vQvsSSo",
  "https://www.youtube.com/embed/seQ0NPF3a6Q",
  "https://www.youtube.com/embed/tPnP01SNX2M",
  "https://www.youtube.com/embed/MaCcU0BSIco",
  "https://www.youtube.com/embed/ugbWQQb-k4g",
  "https://www.youtube.com/embed/j_PAqc7wQTI",
  "https://www.youtube.com/embed/2LCzeAVbcdU",
  "https://www.youtube.com/embed/F_M2xyBRg7Q",
  "https://www.youtube.com/embed/FyTMnxBAkjQ",
  "https://www.youtube.com/embed/gp6iFnUmSnU",
  "https://www.youtube.com/embed/1F-iHdiuhgE",
];
const FormTwoGuide = () => {
  const [video, setVideo] = useState(0);

  return (
    <Styles>
      <img src={img} alt="tmost logo" />
      <iframe
        width="430"
        height="250"
        src={videos[video]}
        title="YouTube video player"
        frameBorder="0"
        allowFullScreen
        rel="0"
        modestbranding="1"
      ></iframe>
      <div className="controls">
        <MainButton
          onClick={() => {
            if (video < videos.length - 1) setVideo(video + 1);
          }}
          className="videoControl"
        >
          {"<"}
        </MainButton>
        العرض:{video + 1}
        <MainButton
          onClick={() => {
            if (video > 0) setVideo(video - 1);
          }}
          className="videoControl"
        >
          {">"}
        </MainButton>
      </div>

      <h4>{"تنزيل المحتوى: يرجى مراعاة الترتيب"}</h4>
      <MainButton onClick={() => window.open(manualURL, "_blank")}>
        Manual
      </MainButton>
      <MainButton onClick={() => window.open(bookURL, "_blank")}>
        كتاب حقوق الاولوية
      </MainButton>
      <MainButton onClick={() => window.open(historyURL, "_blank")}>
        السوابق القضائية
      </MainButton>
      <MainButton onClick={() => window.open(appsURL, "_blank")}>
        التطبيقات العملية
      </MainButton>
      <MainButton onClick={() => window.open(appsSoluURL, "_blank")}>
        اجابة التطبيقات العملية
      </MainButton>
    </Styles>
  );
};

export default FormTwoGuide;
