import React from "react";
import styledComponents from "styled-components";

const Styles = styledComponents.div`
width:100%;
height:100%;
display:flex;
align-items:center;
justify-content:center;
`;

function Container(props) {
  return <Styles>{props.children}</Styles>;
}

export default Container;
