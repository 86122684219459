import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from ".";

export const signIn = async (code) => {
  let userFound = false;
  await signInWithEmailAndPassword(auth, `${code}@tmost.com`, code)
    .then((cred) => {
      userFound = true;
    })
    .catch((err) => (userFound = false));
  return userFound;
};
