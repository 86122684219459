/*
* list comes as
[
    {
        tag,
        id,
        primary,
        secondary,
        class,
    }
]
*
*/

export const sortList = (list) => {
  let count1 = 0,
    count2 = 0;
  list.forEach((right) => {
    if (right.id === 1) count1++;
    if (right.id === 2) count2++;
  });
  list = list.map((right) => ({ ...right, score: right.class * 100 }));
  list = list.map((right) => {
    if (right.id === 1 && count1 > 1) {
      return {
        ...right,
        note: "تنافس مع حق امتياز قانوني آخر، تطبق القوانين الوطنية الخاصة بترتيب حقوق الامتياز.",
      };
    } else if (right.id === 2 && count2 > 1) {
      return {
        ...right,
        note: "تنافس مع حق مقدم خدمات لخدمة المنقول آخر، تطبق القوانين الوطنية الخاصة بترتيب حقوق مقدمي الخدمات.",
      };
    } else return right;
  });

  list.forEach((a) => {
    list.forEach((b) => {
      if (a.class === b.class) {
        if (new Date(a.primary) < new Date(b.primary)) {
          b.score = a.score + 1;
        }
      }
    });
  });
  list.sort((a, b) => a.score - b.score);
  list.forEach((a) => {
    list.forEach((b) => {
      if (a.id === 13 && b.id === 12) {
        if (new Date(a.primary) < new Date(b.secondary)) {
          const temp = a.score;
          a.score = b.score;
          b.score = temp;
        }
      }
    });
  });

  list.sort((a, b) => a.score - b.score);

  return list;
};
