import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
const firebaseConfig = {
  apiKey: "AIzaSyCnnxCunUv63Xv_eYUTi9x9ig0r3PuPos8",
  authDomain: "tmost-39f44.firebaseapp.com",
  projectId: "tmost-39f44",
  storageBucket: "tmost-39f44.appspot.com",
  messagingSenderId: "9762365988",
  appId: "1:9762365988:web:ec761c0f0468d6476baeb1",
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
