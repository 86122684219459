import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  rightsType: 0,
  rightsList: [],
  rightsCount: 0,
  testCase: "",
};

export const infoSlice = createSlice({
  name: "info",
  initialState,
  reducers: {
    setType: (state, action) => {
      state.rightsType = action.payload;
    },
    resetList: (state) => {
      state.rightsList = [];
      state.rightsCount = 0;
      state.testCase = "";
    },
    addToList: (state, action) => {
      state.rightsList.push(action.payload);
      state.rightsCount++;
    },
    removeFromList: (state, action) => {
      if (state.testCase === action.payload) state.testCase = "";
      state.rightsList = state.rightsList.filter(
        (right) => action.payload !== right.tag
      );
    },
    setTestCase: (state, action) => {
      state.testCase = action.payload;
    },
  },
});

export const { setType, resetList, addToList, removeFromList, setTestCase } =
  infoSlice.actions;

export const selectType = (state) => state.info.rightsType;
export const selectRightsList = (state) => state.info.rightsList;
export const selectCount = (state) => state.info.rightsCount;
export const selectTestCase = (state) => state.info.testCase;

export default infoSlice.reducer;
