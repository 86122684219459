import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styledComponents from "styled-components";
import CreatedList from "../components/createdList";
import Window, { BackButton, MainButton } from "../components/window";
import { rightsChoice } from "../data/rights";
import { nextForm, prevForm, toggleHelp } from "../features/form/formSlice";
import {
  addToList,
  selectCount,
  selectRightsList,
  selectType,
} from "../features/info/infoSlice";

const Styles = styledComponents.div`
textarea{
    resize:none;
}
.rightText{
    padding:4px 4px;

}

span > span{
    margin:0 10px;
}
.hide {
  display: none;
}

`;

const getTag = (number) => {
  return String.fromCharCode("A".charCodeAt() + number);
};

function FormThree() {
  const createdList = useSelector(selectRightsList);
  const rightsType = useSelector(selectType);
  const rights = rightsChoice[rightsType];
  const [selection, setSelection] = useState(0);
  const [selectionDate, setSelectionDate] = useState({
    primary: "",
    secondary: "",
  });
  const count = useSelector(selectCount);
  const dispatch = useDispatch();

  return (
    <Styles>
      <Window>
        <select
          value={selection}
          onChange={(e) => {
            setSelectionDate({ primary: "", secondary: "" });
            setSelection(e.target.value);
          }}
        >
          {rights.map((right) => (
            <option key={rights.indexOf(right)} value={rights.indexOf(right)}>
              {right.text}
            </option>
          ))}
        </select>
        <span>
          <span className={`primary ${rights[selection].primary ?? "hide"}`}>
            <label htmlFor="primary">{rights[selection].primary}</label>
            <input
              type="date"
              id="primary"
              value={selectionDate.primary || "2022-01-01"}
              onChange={(e) =>
                setSelectionDate({
                  primary: e.target.value,
                  secondary: selectionDate.secondary,
                })
              }
            />
          </span>
          <span
            className={`secondary ${rights[selection].secondary ?? "hide"}`}
          >
            <label htmlFor="secondary">{rights[selection].secondary}</label>
            <input
              type="date"
              id="secondary"
              value={selectionDate.secondary || "2022-01-01"}
              onChange={(e) =>
                setSelectionDate({
                  primary: selectionDate.primary,
                  secondary: e.target.value,
                })
              }
            />
          </span>
        </span>

        <MainButton
          onClick={() => {
            const text = `${rights[selection].text} \n${rights[selection].primary}:${selectionDate.primary} \n${rights[selection].secondary}:${selectionDate.secondary}`;
            if (
              (rights[selection].primary !== undefined &&
                selectionDate.primary === "") ||
              (rights[selection].secondary !== undefined &&
                selectionDate.secondary === "")
            ) {
              alert("لم يتم إختيار تاريخ");
              return;
            }
            dispatch(
              addToList({
                tag: getTag(count),
                id: rights[selection].id,
                text: text.replace(/\nundefined:/g, ""),
                primary: selectionDate.primary,
                secondary: selectionDate.secondary,
                class: rights[selection].class,
              })
            );
          }}
        >
          إضافة
        </MainButton>

        <CreatedList list={createdList} editable />

        <div>
          <MainButton
            onClick={() => {
              if (createdList.length > 1) dispatch(nextForm());
              else alert("يجب وجود عنصرين على الاقل للترتيب");
            }}
          >
            ترتيب
          </MainButton>
          <MainButton onClick={() => dispatch(toggleHelp())}>
            مراجعة التعليمات
          </MainButton>{" "}
          <BackButton
            onClick={() => {
              dispatch(prevForm());
            }}
          >
            رجوع
          </BackButton>
        </div>
      </Window>
    </Styles>
  );
}

export default FormThree;
