import styledComponents from "styled-components";

export const MainButton = styledComponents.button`
padding:4px 20px;
width:fit-content;
margin: 0 10px;
`;
export const BackButton = styledComponents.button`
padding:4px 20px;
width:fit-content;
margin: 0 10px;
float:left;
`;

export default styledComponents.div`
background-color: #4a2343;
border-radius: 4px;
color:white;
width:640px;
height:400px;
border:0.1px solid #aaaaaa;
padding:14px;
display:flex;
justify-content:space-between;
flex-direction: column;
`;
