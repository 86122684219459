const rl = {
  1: {
    text: "حق امتياز قانوني محدد النوع والمقدار في قانون الضمانات المنقولة.",
    class: 1,
    id: 1,
  },
  2: {
    text: "حقوق مقدمي خدمات خدمة المنقول الضامن",
    class: 2,
    id: 2,
  },
  3: {
    text: "حق مضمون احتيازي مقيد في سجل متخصص أو مؤشـر به قي سند ملكية.",
    primary: "تاريخ القيد في السجل",
    class: 3,
    id: 3,
  },
  4: {
    text: "حقوق بائع أو مؤجر المنقول.",
    primary: "تاريخ القيد في السجل",
    class: 4,
    id: 4,
  },
  5: {
    text: "حق مضمون احتيازي غير مقيد في سجل متخصص أو غير مؤشـر به في سند ملكية.",
    primary: "تاريخ القيد في السجل",
    class: 5,
    id: 5,
  },
  6: {
    text: "حق مضمون مقيد بالسجل العقاري (عقار بالتخصيص)",
    primary: "تاريخ القيد في السجل",
    class: 6,
    id: 6,
  },
  7: {
    text: "حق مضمون مقيد في سجل متخصص ",
    primary: "تاريخ القيد في السجل",
    class: 7,
    id: 7,
  },
  8: {
    text: "حق مضمون مؤشـر به في شهادة الملكية",
    primary: "تاريخ القيد في السجل",
    class: 7,
    id: 8,
  },
  9: {
    text: "حق مضمون بواسطة حيازة المنقول (حائز حسن النية). ",
    primary: "تاريخ الحيازة",
    class: 8,
    id: 9,
  },
  10: {
    text: "حق مضمون مقيد بسجل الضمانات المنقولة",
    primary: "تاريخ القيد في السجل",
    secondary: "تاريخ منح الائتمان",
    class: 8,
    id: 10,
  },
  11: {
    text: "حكم قضائي مقيد بسجل الضمانات المنقولة",
    primary: "التاريخ",
    class: 8,
    id: 11,
  },
  12: {
    text: "حكم قضائي غير مقيد بسجل الضمانات المنقولة",
    primary: "تاريخ الإعلان بالصيغة التنفيذية",
    secondary: "تاريخ انتهاء فترة السماح",
    class: 9,
    id: 12,
  },
  13: {
    text: "ائتمان آجل محدد بمبلغ ثابت.",
    primary: "تاريخ القيد في السجل",
    class: 10,
    id: 13,
  },
  14: {
    text: "حق مضمون بواسطة حيازة المستند القابل للتداول (حائز حسن النية).",
    primary: "تاريخ الحيازة",
    class: 3,
    id: 14,
  },
  15: {
    text: "حق مضمون بواسطة حيازة المخزونات التي يشتمل عليها المستند القابل للتداول (حائز حسن النية).",
    class: 4,
    id: 15,
  },
  16: {
    text: "حق مضمون بواسطة حيازة المنقولات المادية غير المخزونات التي يشتمل عليها المستند القابل للتداول (حائز حسن النية).",
    primary: "تاريخ الحيازة",
    class: 3,
    id: 16,
  },
  17: {
    text: "حق مضمون بواسطة حيازة المستند أو الصك القابل للتداول (حائز حسن النية).",
    class: 3,
    id: 17,
  },
  18: {
    text: "حق مضمون بواسطة حيازة الدائن المضمون لإقرار صادر من مُصدر التعهد المستقل بأحقية الدائن المضمون في تقاضي المستحقات المتأتية من التعهد (حائز حسن النية).",
    class: 2,
    id: 18,
  },
  19: {
    text: "حق مضمون بواسطة حيازة المنقولات أو المستحقات أو حيازة المستندات التي يشتمل عليها التعهد المستقل (حائز حسن النية).",
    class: 4,
    id: 19,
  },
  20: {
    text: "حق مضمون بواسطة اتفاق تملك الدائن المضمون عميل البنك المودع لديه للحساب المصرفي .",
    primary: "تاريخ الاتفاق",
    class: 4,
    id: 20,
  },
  21: {
    text: "حق دائنية مقرر للبنك المودع لديه بدون اتفاق مقاصة.",
    class: 5,
    id: 21,
  },
  22: {
    text: "حق مضمون بواسطة تحرير البنك المودع لديه (الدائن المضمون) اتفاق مقاصة.",
    class: 6,
    id: 22,
  },
  23: {
    text: "حق مضمون بواسطة اتفاق تملك الدائن المضمون من غير عملاء البنك المودع لديه للحساب المصرفي .",
    primary: "تاريخ الاتفاق",
    class: 7,
    id: 23,
  },
  24: {
    text: "معاملة إحالة مستحقات مقيدة بسجل الضمانات المنقولة",
    primary: "تاريخ القيد في السجل",
    class: 8,
    id: 24,
  },
  25: {
    text: "حائز النقود (حسن النية)",
    class: 2,
    id: 25,
  },
};

const rights1 = [
  rl[1],
  rl[2],
  rl[3],
  rl[4],
  rl[5],
  rl[6],
  rl[7],
  rl[8],
  rl[9],
  rl[10],
  rl[11],
  rl[12],
  rl[13],
];
const rights2 = [
  rl[1],
  rl[2],
  rl[3],
  rl[4],
  rl[5],
  rl[9],
  rl[10],
  rl[11],
  rl[12],
  rl[13],
];
const rights3 = [
  rl[1],
  rl[2],
  rl[3],
  rl[4],
  rl[5],
  rl[7],
  rl[9],
  rl[10],
  rl[11],
  rl[12],
  rl[13],
];
const rights4 = [
  rl[1],
  rl[2],
  rl[3],
  rl[4],
  rl[5],
  rl[7],
  rl[9],
  rl[10],
  rl[11],
  rl[12],
  rl[13],
];
const rights5 = [
  rl[1],
  rl[2],
  rl[3],
  rl[4],
  rl[5],
  rl[7],
  rl[9],
  rl[10],
  rl[11],
  rl[12],
  rl[13],
];
const rights6 = [rl[1], rl[14], rl[15], rl[10], rl[11], rl[12], rl[13]];
const rights7 = [rl[1], rl[14], rl[16], rl[10], rl[11], rl[12], rl[13]];
const rights8 = [
  rl[1],
  rl[3],
  rl[4],
  rl[5],
  rl[7],
  rl[9],
  rl[10],
  rl[11],
  rl[12],
  rl[13],
];
const rights9 = [rl[1], rl[17], rl[10], rl[11], rl[12], rl[13]];
const rights10 = [rl[1], rl[18], rl[19], rl[10], rl[11], rl[12], rl[13]];
const rights11 = [
  rl[1],
  rl[20],
  rl[21],
  rl[22],
  rl[23],
  rl[10],
  rl[11],
  rl[12],
  rl[13],
];
const rights12 = [rl[1], rl[24], rl[10], rl[11], rl[12], rl[13]];
const rights13 = [rl[1], rl[25], rl[10], rl[11], rl[12], rl[13]];

export const rightsChoice = [
  rights1,
  rights2,
  rights3,
  rights4,
  rights5,
  rights6,
  rights7,
  rights8,
  rights9,
  rights10,
  rights11,
  rights12,
  rights13,
];
