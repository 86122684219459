import { configureStore } from "@reduxjs/toolkit";
import formReducer from "../features/form/formSlice";
import infoReducer from "../features/info/infoSlice";

export const store = configureStore({
  reducer: {
    form: formReducer,
    info: infoReducer,
  },
});
