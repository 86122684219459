import React from "react";
import styledComponents from "styled-components";
const Styles = styledComponents.div`
textarea{
    width: 100%;
    height: 200px;
    resize:none;
  }
`;
const text = `
•يقوم العميل بإدخال البيانات.
•يسمح البرنامج للعميل بإدراج تواريخ مستقبلية، مثلاً: تسجيل معاملة افتراضية يتوقع حدوثها 2030.
•تظهر للعميل نتائج العملية.

بعد ادخال العميل للبيانات يتم تحديد الحق محل البحث عن طريق الضغط على الرمز المطلوب.
يجب وجود حقين على الاقل ليعمل البرنامج بشكل صحيح.

يمكن مسح الحقوق عن طريق الضغط على علامة 'X' يسار الحق
`;
const FormThreeGuide = () => (
  <Styles>
    <textarea value={text}></textarea>
  </Styles>
);
export default FormThreeGuide;
