import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styledComponents from "styled-components";
import image from "./guides/tmost.png";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { prevForm } from "../features/form/formSlice";
import {
  selectRightsList,
  selectTestCase,
  selectType,
} from "../features/info/infoSlice";
import { sortList } from "../helper/sortRights";
const Styles = styledComponents.div`
h1:last-of-type{
  margin-bottom:40px;
}
#printToPDF{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
  direction: rtl;
  padding: 50px;
}
p{
  width: 700px;
}
img{
  align-self: flex-end;
}
td,th{
  border: 1px solid black;
  text-align: right;
  padding: 4px;
}
table{
  border: 1px solid black;

}
table{
  width: 700px;
}
`;
const printDocument = async (numberOfElements) => {
  const input = document.getElementById("printToPDF");
  const info = document.getElementById("imp-info");
  const pdf = new jsPDF({ orientation: "p", unit: "mm", format: "a4" });
  const heightOfTable = 100 + numberOfElements * 12;
  const xOfInfo = heightOfTable + 100 > 290 ? 10 : heightOfTable + 1;
  await html2canvas(input).then((canvas) => {
    const imgData = canvas.toDataURL("image/png");
    pdf.addImage(imgData, "png", 20, 0, 170, heightOfTable);
    if (heightOfTable + 100 > 290) {
      pdf.addPage();
    }

    // pdf.output('dataurlnewwindow');
  });
  await html2canvas(info).then((canvas) => {
    const imgData = canvas.toDataURL("image/png");
    pdf.addImage(imgData, "png", 30, xOfInfo, 150, 100);
    // pdf.output('dataurlnewwindow');
  });
  pdf.save("TMOST results.pdf");
};
const typesNames = [
  "منقول مادي.",
  "مواد أولوية مندمجة في كتلة مماثلة.",
  "مواد أولوية مندمجة في منتج مماثل.",
  "مواد أولوية مندمجة في كتلة مختلفة.",
  "مواد أولوية مندمجة في منتج مختلف.",
  "مخزونات واردة في مستند قابل للتداول.",
  "منقول معنوي غيـر المستحقات.",
  "مستحقات متأتية من مستند أو صك قابل للتداول (شيك، كمبالة، سند لأمر، سندات شحن، إيصالات مستودع).",
  "مستحقات متأتية من (خطاب ضمان، خطاب اعتماد، كفالة مصرفية).",
  "حقوق تقاضي أموال مودعة في حساب مصرفي.",
  "المستحقات المدنية (حوالة الحق و حوالة الدين).",
  "نقود معينة تعيينًا ذاتيًا.",
];

const PrintablePDF = () => {
  const dispatch = useDispatch();
  const typeOfRight = useSelector(selectType);
  const createdList = useSelector(selectRightsList);
  const testCase = useSelector(selectTestCase);

  useEffect(() => {
    const fun = async () => {
      await printDocument(createdList.length);
      dispatch(prevForm());
    };
    fun();
  }, [dispatch, createdList.length]);

  return (
    <Styles>
      <div id="printToPDF">
        <img src={image} alt="test" />
        <h1>تطبيق TMOST </h1>
        <h1>لترتيب حقوق الأولوية لدائني المعاملات المضمونة</h1>
        <table>
          <thead>
            <th>نوع المنقول الضامن</th>
            <th>{typesNames[typeOfRight]}</th>
          </thead>
          <tbody>
            {sortList(createdList).map((right) => (
              <tr>
                <td>{right.tag === testCase ? "XY" : right.tag}</td>
                <td>
                  {right.text}
                  {right.note
                    ? `
${right.note}`
                    : ""}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <p id="imp-info">
        معلومات هامة: <br /> (1)الحق المضمون الاحتيازي هو الحق الناشئ عن معاملة
        تمويل احتياز منقولات مادية أو منقولات معنوية. <br />
        (2) مقدمي خدمات خدمة المنقول المادي الضامن، هم مقدمي أعمال التخزين
        والتغلفة والإصلاح والنقل وغيرها من الخدمات التي تحافظ على المنقول المادي
        الضامن. <br />
        (3) لا يجوز المطالبة بقيمة حق مضمون يتجاوز مقداره قيمة الالتزام محل
        الضمان. <br />
        (4) تحظى الائتمانات التابعة بذات درجة الأولوية الممنوحة للائتمان الأصلي،
        في حدود المبلغ الأقصى المقيد في سجل الضمانات المنقولة لإنفاذ الحق
        المضمون فيه. <br />
        (5) تمتد درجة أولوية الحق المضمون إلى عائدات المنقول الضامن. <br />
        (6) الدائن المضمون حسن النية هو الذي لا يعلم بوجود اتفاق ضماني يحظر نقل
        المنقولات الضامنة للغير. <br />
        (7) يجب أن يكون الدائن المضمون حسن النية لكي يستفيد من قواعد الأولوية
        غير الزمنية الواردة في هذا التطبيق.
        <br />
        (8) يجب أن يكون الدائن المضمون الحائز حسن النية لكي يستفيد من قواعد
        أولوية حقوق الحيازة الواردة في هذا التطبيق. <br />
        (9) يشـترط لتمتع الحق المضمون الاحتيازي (وعائداته) غير المقيد في سجل
        متخصص أو غير المؤشـر به قي سند ملكية، بأولوية مطلقة على غيره من الحقوق
        المضمونة أن تتحق الشـروط التالية: • السلع الاستهلاكية: أن يقيد الحق
        المضمون في سجل الضمانات المنقولة. • السلع التشغيلية: أن يقيد الحق
        المضمون في سجل الضمانات المنقولة خلال من 20: 30 يوم من تاريخ احتياز مانح
        الضمان للسلع التشغيلية. • السلع التخزينية: أن يقيد الحق المضمون في سجل
        الضمانات المنقولة قبل تاريخ احتياز مانح الضمان للسلع التخزينية وإخطار
        الدائنين بتمسك الدائن المضمون الاحتيازي بدرجة الأولوية المطلقة. <br />
        (10) تمتد درجة أولوية الحق المضمون الاحتيازي إلى جميع معاملات الاحتياز
        التي يمولها ذات ممول الاحتياز، أي يكفي تسجيل واحد في السجل وإخطار واحد
        للدائنين.
        <br />
        (11) لا يجوز المطالبة بنسبة من إجمالي قيمة المنتج أو الكتلة تتجاوز نسبة
        مساهمة المنقولات الضامنة في ذلك المنتج أو الكتلة.
      </p>
    </Styles>
  );
};

export default PrintablePDF;
