import React, { useState } from "react";
import { useDispatch } from "react-redux";
import styledComponents from "styled-components";
import Window from "../components/window";
import { MainButton } from "../components/window";
import { nextForm } from "../features/form/formSlice";
import { signIn } from "../firebase/auth";

const Styles = styledComponents.div`
textarea{
  width: 100%;
  height: 300px;
  resize:none;
  background-color:#4a2343;
  line-height: 1.5em;
  color:white;
  border:white 0.1px solid;
  padding:8px;
  
}
input {
  align-self: flex-start;
}
span {
  font-size:14px;
}
input[type="radio"]{
  margin-left:3px
}
`;

function FormOne() {
  const [accepted, setAccept] = useState(true);
  const [text, settext] = useState("");
  const dispatch = useDispatch();
  const defValue = `
                                                                          تطبيق TMOST 
                                            لترتيب حقوق الأولوية لدائني المعاملات المضمونة 

عن تطبيق TMOST:
يقوم تطبيق TMOST بحساب درجة حق الدائن بالمقارنة بغيره من حقوق الدائنين المقررة على ممتلكات منقولة وفقًا لتوصيات لجنة الأمم المتحدة للقانون التجاري الدولي المقررة بشأن الضمانات المنقولة.

أهمية تطبيق TMOST:
يسهل تطبيق TMOST إمكانية  تنبؤ الدائن المضمون بوضعه القانوني (درجة حقه المضمون) قبل منح الائتمان.

إخلاء المسؤولية:
تخلي TMOST مسؤوليتها عن نتائج تحليل البيانات:
 (1) في الحالة التي يقوم فيها العميل بإدخال بيانات غير صحيحة. 
 (2) في الحالة التي تكون فيها القوانين الوطنية غير مطابقة لتوصيات لجنة الأمم المتحدة للقانون التجاري الدولي المقررة بشأن المعاملات المضمونة.

يسعدنا تلقي مقترحاتكم حول تطوير تطبيق TMOST وتصحيح الأخطاء على info@tmost.co 

كيفية عمل التطبيق
•	يقوم العميل باختيار المعاملة المطلوبة من ضمن القوائم الموضوعة.
•	يقوم العميل بإدخال البيانات.
•	يسمح البرنامج للعميل بإدراج تواريخ مستقبلية، مثلاً: تسجيل معاملة افتراضية يتوقع حدوثها 2030.
•	تظهر للعميل نتائج العملية.
  `;

  return (
    <Styles>
      <Window>
        <textarea value={defValue} readOnly></textarea>
        <span>
          <input
            type="radio"
            onClick={(e) => {
              setAccept(false);
            }}
          />
          لقد قرأت التعليمات و الشروط، و أقر انه في حالة عدم اتباعها فإن التطبيق
          لن يعمل كما يجب.
        </span>
        <span>
          <input
            type="text"
            placeholder="كود العميل (في الكتاب)"
            value={text}
            onChange={(e) => settext(e.target.value)}
          />
          <MainButton
            onClick={async () => {
              const isCorrect = await signIn(text);
              if (isCorrect) dispatch(nextForm());
              else alert("يرجى مراجعة الكود");
            }}
            disabled={accepted}
          >
            متابعة
          </MainButton>
        </span>
      </Window>
    </Styles>
  );
}

export default FormOne;
