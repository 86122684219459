import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styledComponents from "styled-components";
import Window, { MainButton } from "../components/window";
import { selectForm, toggleHelp } from "../features/form/formSlice";
import FormThreeGuide from "./guides/FormThreeGuide";
import FormTwoGuide from "./guides/FormTwoGuide";

const Styles = styledComponents.div`
&&&{
  position:absolute;
  z-index:10;
}

`;

const guideSelect = (form) => {
  switch (form) {
    case 1:
      return <FormTwoGuide />;
    case 2:
      return <FormThreeGuide />;
    default:
      return <h1>Error, no form</h1>;
  }
};

function Guide() {
  const dispatch = useDispatch();
  const form = useSelector(selectForm);

  return (
    <Styles>
      <Window>
        {guideSelect(form)}
        <MainButton onClick={() => dispatch(toggleHelp())}>اغلاق</MainButton>
      </Window>
    </Styles>
  );
}

export default Guide;
