import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styledComponents from "styled-components";
import Window from "../components/window";
import { MainButton } from "../components/window";
import { nextForm, toggleHelp } from "../features/form/formSlice";
import {
  selectType,
  resetList,
  setType,
  selectRightsList,
} from "../features/info/infoSlice";
const Styles = styledComponents.div`
textarea{
  width: 100%;
  height: 200px;
  resize:none;
}
select{
  width:100%;
}
`;

const text = `
(1)يجب مراعاة صحة ودقة البيانات المدخلة بواسطة العميل.
(2)لا يتيح هذا التطبيق تحليل بيانات الحقوق المضمونة التي نشأت من أكثر من مانح ضمان.
(3)يجب أن يكون الحق المضمون المدخلة بياناته في التطبيق مازال قائمًا ولم يسقط أو يتم شطبه قانونًا.
(4)في حالة الحقوق المضمونة الواردة على منقولات معنوية غير المستحقات، يجب أن تكون نتائج البحث متفقة مع القوانين الوطنية الخاصة بالملكية الفكرية، فإذا لم تتفق تكون الغلبة للقوانين الوطنية الخاصة بالملكية الفكرية.
(5)لا تسري نتائج هذا التطبيق على معدات الطائرات، الأوراق المالية المودعة لدى وسيط والمستحقات المتأتية منها، المستحقات المتأتية من عقود مالية تحكمها اتفاقات معاوضة مالم تكن هذه المستحقات متعين تسديدها لدى إنهاء جميع المعاملات العالقة، والمستحقات الناشئة عن معاملات النقد الأجنبي.
`;
function FormTwo() {
  const dispatch = useDispatch();
  const type = useSelector(selectType);
  const list = useSelector(selectRightsList);
  return (
    <Styles>
      <Window>
        <div>
          <p style={{ fontSize: "smaller", marginBottom: "4px" }}>
            نوع المنقول الضامن:
          </p>
          <select
            value={type}
            onChange={(e) => {
              if (list.length !== 0) {
                if (window.confirm("سيتم افراغ قائمة الحقوق بعد التغيير")) {
                  dispatch(setType(e.target.value));
                  dispatch(resetList());
                }
              } else {
                dispatch(setType(e.target.value));
                dispatch(resetList());
              }
            }}
          >
            <option value="0">(1) منقول مادي.</option>
            <option value="1">(2) مواد أولوية مندمجة في كتلة مماثلة.</option>
            <option value="2">(3) مواد أولوية مندمجة في منتج مماثل.</option>
            <option value="3">(4) مواد أولوية مندمجة في كتلة مختلفة.</option>
            <option value="4">(5) مواد أولوية مندمجة في منتج مختلف.</option>
            <option value="5">(6) مخزونات واردة في مستند قابل للتداول.</option>
            <option value="6">
              (7) منقولات مادية غير المخزونات واردة في مستند قابل للتداول.
            </option>
            <option value="7">(8) منقول معنوي غيـر المستحقات.</option>
            <option value="8">
              (9) مستحقات متأتية من مستند أو صك قابل للتداول (شيك، كمبالة، سند
              لأمر، سندات شحن، إيصالات مستودع).
            </option>
            <option value="9">
              (10) مستحقات متأتية من (خطاب ضمان، خطاب اعتماد، كفالة مصرفية).
            </option>
            <option value="10">
              (11) حقوق تقاضي أموال مودعة في حساب مصرفي.
            </option>
            <option value="11">
              (12) المستحقات المدنية (حوالة الحق و حوالة الدين).
            </option>
            <option value="12">(13) نقود معينة تعيينًا ذاتيًا.</option>
          </select>
        </div>
        <textarea value={text} readOnly></textarea>
        <div>
          <MainButton
            onClick={() => {
              dispatch(nextForm());
            }}
          >
            اختيار
          </MainButton>
          <MainButton onClick={() => dispatch(toggleHelp())}>
            مراجعة المحتوى
          </MainButton>
        </div>
      </Window>
    </Styles>
  );
}

export default FormTwo;
