import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styledComponents from "styled-components";
import CreatedList from "../components/createdList";
import Window, { BackButton, MainButton } from "../components/window";
import { nextForm, prevForm } from "../features/form/formSlice";
import { selectRightsList } from "../features/info/infoSlice";
import { sortList } from "../helper/sortRights";

const Styles = styledComponents.div`
textarea{
  width: 100%;
  height: 200px;
  resize:none;
  background-color:#4a2343;
  line-height: 1.5em;
  color:white;
  border:white 0.1px solid;
  padding:8px;
}
`;

const defValue = `
معلومات هامة:
(1)	الحق المضمون الاحتيازي هو الحق الناشئ عن معاملة تمويل احتياز منقولات مادية أو منقولات معنوية.
(2)	مقدمي خدمات خدمة المنقول المادي الضامن، هم مقدمي أعمال التخزين والتغلفة والإصلاح والنقل وغيرها من الخدمات التي تحافظ على المنقول المادي الضامن.
(3)	لا يجوز المطالبة بقيمة حق مضمون يتجاوز مقداره قيمة الالتزام محل الضمان.
(4)	تحظى الائتمانات التابعة بذات درجة الأولوية الممنوحة للائتمان الأصلي، في حدود المبلغ الأقصى المقيد في سجل الضمانات المنقولة لإنفاذ الحق المضمون فيه.
(5)	تمتد درجة أولوية الحق المضمون إلى عائدات المنقول الضامن.
(6)	الدائن المضمون حسن النية هو الذي لا يعلم بوجود اتفاق ضماني يحظر نقل المنقولات الضامنة للغير.
(7)	يجب أن يكون الدائن المضمون حسن النية لكي يستفيد من قواعد الأولوية غير الزمنية الواردة في هذا التطبيق.
(8)	يجب أن يكون الدائن المضمون الحائز حسن النية لكي يستفيد من قواعد أولوية حقوق الحيازة الواردة في هذا التطبيق.
(9)	يشـترط لتمتع الحق المضمون الاحتيازي (وعائداته) غير المقيد في سجل متخصص أو غير المؤشـر به قي سند ملكية، بأولوية مطلقة على غيره من الحقوق المضمونة أن تتحق الشـروط التالية:
•	السلع الاستهلاكية: أن يقيد الحق المضمون في سجل الضمانات المنقولة.
•	السلع التشغيلية: أن يقيد الحق المضمون في سجل الضمانات المنقولة خلال من 20: 30 يوم من تاريخ احتياز مانح الضمان للسلع التشغيلية.
•	السلع التخزينية: أن يقيد الحق المضمون في سجل الضمانات المنقولة قبل تاريخ احتياز مانح الضمان للسلع التخزينية وإخطار الدائنين بتمسك الدائن المضمون الاحتيازي بدرجة الأولوية المطلقة.
(10)	تمتد درجة أولوية الحق المضمون الاحتيازي إلى جميع معاملات الاحتياز التي يمولها ذات ممول الاحتياز، أي يكفي تسجيل واحد في السجل وإخطار واحد للدائنين.
(11)	لا يجوز المطالبة بنسبة من إجمالي قيمة المنتج أو الكتلة تتجاوز نسبة مساهمة المنقولات الضامنة في ذلك المنتج أو الكتلة.
(12)	لا يجوز المطالبة بقيمة تتجاوز قيمة المنقولات الضامنة وقت دمجها ما لم يكن قد حدث تضخم أدى لزيادة قيمتها. 

`;
function FormFour() {
  const createdList = useSelector(selectRightsList);
  const dispatch = useDispatch();
  const sortedList = sortList(createdList);
  return (
    <Styles>
      <Window>
        <CreatedList list={sortedList} />
        <textarea value={defValue} readOnly></textarea>
        <div>
          <MainButton
            onClick={() => {
              dispatch(nextForm());
            }}
          >
            تنزيل PDF
          </MainButton>
          <BackButton
            onClick={() => {
              dispatch(prevForm());
            }}
          >
            رجوع
          </BackButton>
        </div>
      </Window>
    </Styles>
  );
}

export default FormFour;
