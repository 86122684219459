import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styledComponents from "styled-components";
import {
  removeFromList,
  selectTestCase,
  setTestCase,
} from "../features/info/infoSlice";

const Styles = styledComponents.div`
color:black;
background-color: white;
height:130px;
overflow-y:scroll;
.right{
  display:flex;
  border: 0.2px solid #99999999;
}
.tag{
  width:45px;
  font-size:1.1rem;
  font-weight:500;
  background-color: #EEEEEE;
  text-align:center;
  cursor:${({ editable }) => (editable ? "pointer" : "default")} ;

}

.cancel{
    cursor:pointer;
    margin:0 auto 0 5px;
}
.rightText{
  white-space:pre-wrap;
  width:95%;
}
`;

function CreatedList({ list, editable }) {
  const testCase = useSelector(selectTestCase);
  const dispatch = useDispatch();
  return (
    <div>
      {editable && (
        <p style={{ fontSize: "smaller", marginBottom: "4px" }}>
          *لاختيار الحق محل البحث يمكن الضغط على الرمز في الجدول
        </p>
      )}
      <Styles editable={editable}>
        {list.map((right) => (
          <div key={right.tag} className="right">
            <div
              className="tag"
              onClick={
                editable ? () => dispatch(setTestCase(right.tag)) : undefined
              }
            >
              {right.tag === testCase ? "XY" : right.tag}
            </div>
            <div className="rightText">
              {right.text}
              {right.note
                ? `
${right.note}`
                : ""}
            </div>
            {editable && (
              <span
                className="cancel"
                onClick={() => {
                  dispatch(removeFromList(right.tag));
                }}
              >
                X
              </span>
            )}
          </div>
        ))}
      </Styles>
    </div>
  );
}

export default CreatedList;
